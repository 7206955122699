<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        {{ form.driver.surname }} {{ form.driver.name }}
        <span v-if="form.driver.patronymic">{{ form.driver.patronymic }}</span>
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Вид перевозки"
              >
                <b-form-radio-group v-model="accessType">
                  <b-form-radio
                    v-model="accessType"
                    name="accessType"
                    value="auto"
                    @change="clearCheckType()"
                  >
                    Автоперевозка
                  </b-form-radio>
                  <b-form-radio
                    v-model="accessType"
                    name="accessType"
                    value="container"
                    @change="clearCheckType()"
                  >
                    ЖД перевозка (только на ЖД рейсы на доставку контейнера до ЖД станции)
                  </b-form-radio>
                  <b-form-radio
                    v-model="accessType"
                    name="accessType"
                    value="cargoFlow"
                    @change="clearCheckType()"
                  >
                    Услуга грузопровод (только по услуги грузопровод)
                  </b-form-radio>
                  <b-form-input
                    v-model="accessType"
                    disabled
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.driver.driverAccessAuto && expressDriverCheck">
            <b-col md="12">
              <b-form-radio-group
                label="Тип проверки"
              >
                <b-form-radio-group v-model.trim="$v.form.checkType.$model">
                  <b-form-radio
                    name="checkType"
                    :value="0"
                  >
                    Обычная проверка (личное посещение водителя СБ ПЭК – обычный пакет документов для проверки)
                  </b-form-radio>
                  <b-form-radio
                    name="checkType"
                    :value="1"
                  >
                    Экспресс проверка (без личного посещения водителя СБ ПЭК – расширенный пакет документов)
                  </b-form-radio>
                  <b-form-input
                    v-model.trim="$v.form.checkType.$model"
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row v-if="form.checkType===0">
            <b-col md="4">
              <b-form-group
                class="form-group-el-select required"
                label="Отделение ПЭК для посещения его водителем для окончательной проверки"
              >
                <!--                <b-form-input-->
                <!--                  v-model="form.checkLocation"-->
                <!--                  disabled-->
                <!--                  class="d-none"-->
                <!--                />-->
                <el-select
                  id="checkLocation"
                  v-model="form.checkLocation"
                  class="form-control"
                  name="checkLocation"
                  placeholder="Выберите отделение"
                  clearable
                >
                  <el-option
                    v-for="item in driverCheckLocations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <b-form-invalid-feedback id="input1LiveFeedback1">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <h5 class="custom-fieldset__title">
            Паспортные данные
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-group
                id="passportInputGroup1"
                class="form-group-el-select"
                label="Паспорт"
                label-for="passport-type"
              >
                <b-form-input
                  v-model.trim="$v.form.driver.passportType.$model"
                  :disabled="form.driver.status == 1"
                  :state="chkState('driver.passportType')"
                  aria-describedby="input1LiveFeedback1"
                  class="d-none"
                />
                <el-select
                  id="passport-type"
                  v-model="$v.form.driver.passportType.$model"
                  class="form-control"
                  name="passport-type"
                  placeholder="Выберите тип паспорта"
                  clearable
                  :disabled="form.driver.status == 1"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in passportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
                <b-form-invalid-feedback id="input1LiveFeedback1">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    id="passportSeriesInputGroup2"
                    label="Серия"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-series"
                      v-model.trim="$v.form.driver.passportSeries.$model"
                      type="text"
                      name="passport-series"
                      :state="chkState('driver.passportSeries')"
                      aria-describedby="input1LiveFeedback2"
                      autocomplete="passport-series"
                      :disabled="form.driver.status == 1"
                      @keyup="chkSeriaField($event)"
                      @keydown="chkSeriaField($event)"
                      @keydown.space="(e) => e.preventDefault()"
                      @blur="chkSeriaField($event)"
                      @paste="chkSeriaField($event)"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback2">
                      <span v-if="!$v.form.driver.passportSeries.required">Это поле обязательно для заполнения</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    id="passportNumberInputGroup3"
                    label="Номер"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-number"
                      v-model.trim="$v.form.driver.passportNumber.$model"
                      type="number"
                      name="passport-number"
                      :state="chkState('driver.passportNumber')"
                      aria-describedby="input1LiveFeedback3"
                      autocomplete="passport-number"
                      :disabled="form.driver.status == 1"
                      @keydown.space="(e) => e.preventDefault()"
                    />
                    <b-form-invalid-feedback id="input1LiveFeedback3">
                      <span v-if="!$v.form.driver.passportNumber.required">Это поле обязательно для заполнения</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportIssueDateInputGroup4"
                label="Дата выдачи"
                label-for="passport-issue-date"
              >
                <b-form-input
                  v-model.trim="$v.form.driver.passportIssueDate.$model"
                  :disabled="form.driver.status == 1"
                  :state="chkState('driver.passportIssueDate')"
                  aria-describedby="input1LiveFeedback4"
                  class="d-none"
                />
                <el-date-picker-input
                  id="passport-issue-date"
                  v-model.trim="form.driver.passportIssueDate"
                  :disabled="form.driver.status == 1"
                  type="date"
                  name="passport-issue-date"
                  autocomplete="passport-issue-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  <span v-if="!$v.form.driver.passportIssueDate.required">Это поле обязательно для заполнения</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                id="passportIssuedByInputGroup5"
                label="Кем выдан"
                label-for="passport-issued-by"
              >
                <b-form-input
                  id="passport-issued-by"
                  v-model.trim="$v.form.driver.passportIssuedBy.$model"
                  type="text"
                  name="passport-issued-by"
                  :disabled="form.driver.status == 1"
                  :state="chkState('driver.passportIssuedBy')"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="passport-issued-by"
                />
                <b-form-invalid-feedback id="input1LiveFeedback5">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup6"
                label="Фамилия"
                label-for="surname"
              >
                <b-form-input
                  id="surname"
                  v-model.trim="$v.form.driver.surname.$model"
                  type="text"
                  name="surname"
                  :state="chkState('driver.surname')"
                  :disabled="form.driver.status == 1 || form.driver.status == 3"
                  aria-describedby="input1LiveFeedback6"
                  autocomplete="surname"
                />
                <b-form-invalid-feedback id="input1LiveFeedback6">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup7"
                label="Имя"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model.trim="$v.form.driver.name.$model"
                  type="text"
                  name="name"
                  :state="chkState('driver.name')"
                  :disabled="form.driver.status == 1 || form.driver.status == 3"
                  aria-describedby="input1LiveFeedback7"
                  autocomplete="name"
                />
                <b-form-invalid-feedback id="input1LiveFeedback7">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup8"
                label="Отчество"
                label-for="patronymic"
              >
                <b-form-input
                  id="patronymic"
                  v-model.trim="$v.form.driver.patronymic.$model"
                  type="text"
                  name="patronymic"
                  autocomplete="patronymic"
                  :disabled="form.driver.status == 1 || form.driver.status == 3"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="birthdateInputGroup"
                label="Дата рождения"
                label-for="birth-date"
              >
                <el-date-picker-input
                  id="birth-date"
                  v-model.trim="$v.form.driver.birthDate.$model"
                  type="date"
                  format="dd.MM.yyyy"
                  name="birth-date"
                  :picker-options="pickerOptions"
                  :disabled="form.driver.status != 0"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="innInputGroup7"
                label="ИНН водителя"
                class="required"
                label-for="inn"
              >
                <b-form-input
                  id="inn"
                  v-model.trim="$v.form.driver.inn.$model"
                  type="text"
                  name="inn"
                  :state="chkState('driver.inn')"
                  aria-describedby="innInputGroup7"
                  autocomplete="inn"
                  :disabled="!(form.driver.status == 0 || form.driver.status == 1)"
                />
                <b-form-invalid-feedback id="innLiveFeedback7">
                  <span v-if="!$v.form.driver.inn.required">Это поле обязательно для заполнения</span>
                  <span v-if="!$v.form.driver.inn.minLength || !$v.form.driver.inn.maxLength">
                    Инн должен содержать 12 символов
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportScreenFirstInputGroup16"
                label="Главная страница паспорта"
                label-for="passport-main-page"
              >
                <b-form-file
                  id="passport-main-page"
                  v-model.trim="$v.form.passportScreenFirst.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('passportScreenFirst')"
                  :disabled="form.driver.status == 1"
                  name="passport-main-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('passportScreenFirst')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback9">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.passportScreenFirst && form.passportScreenFirst.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    v-if="form.passportScreenFirst.file"
                    :href="'/files/' + form.passportScreenFirst.file ? form.passportScreenFirst.file.guid : ''"
                    target="_blank"
                    @click.prevent="downloadFile(form.passportScreenFirst.file)"
                  >{{ form.passportScreenFirst.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportScreenSecondInputGroup17"
                label="Страница с пропиской"
                label-for="passport-registration-page"
              >
                <b-form-file
                  id="passport-registration-page"
                  v-model.trim="$v.form.passportScreenSecond.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('passportScreenSecond')"
                  :disabled="form.driver.status == 1"
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('passportScreenSecond')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback10">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.passportScreenSecond && form.passportScreenSecond.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    v-if="form.passportScreenSecond.file"
                    :href="'/files/' + form.passportScreenSecond.file ? form.passportScreenSecond.file.guid : ''"
                    target="_blank"
                    @click.prevent="downloadFile(form.passportScreenSecond.file)"
                  >{{ form.passportScreenSecond.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="contractFilesInputGroup18"
                label="Документы договорных отношений водителя и работодателя"
                label-for="contract-file"
              >
                <b-form-file
                  v-model.trim="$v.form.contractFiles.$model"
                  :disabled="form.driver.status == 1 || form.driver.status == 3"
                  :state="chkState('contractFiles')"
                  aria-describedby="input1LiveFeedback11"
                  multiple
                  class="d-none"
                />
                <b-form-file
                  id="contract-file"
                  v-model.trim="contractFiles"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :disabled="form.driver.status == 1 || form.driver.status == 3"
                  name="contract-file"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                  @input="uploadFiles('contractFiles')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback11">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.contractFiles"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in form.contractFiles"
                    :key="`file-${index}`"
                  >
                    <a
                      v-if="file"
                      :href="'/files/' + file.file ? file.file.guid : ''"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>&nbsp;<a
                      href="#"
                      class="fa fa-close text-danger"
                      @click="deleteFileFromMultipleField('contractFiles', index)"
                    />;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="driverLicenseInputGroup9"
                label="Номер водительского удостоверения"
                label-for="drivers-license"
              >
                <b-form-input
                  id="drivers-license"
                  v-model.trim="$v.form.driver.driverLicense.$model"
                  type="text"
                  name="drivers-license"
                  :state="chkState('driver.driverLicense')"
                  :disabled="form.driver.status == 3"
                  aria-describedby="input1LiveFeedback9"
                  autocomplete="drivers-license"
                />
                <b-form-invalid-feedback id="input1LiveFeedback9">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="driverLicenseScreensGroup15"
                label="Водительское удостоверение"
                label-for="drivers-license-cover-page"
              >
                <b-form-file
                  v-model.trim="$v.form.driverLicenseScreens.$model"
                  :disabled="form.driver.status == 3"
                  :state="chkState('driverLicenseScreens')"
                  aria-describedby="input1LiveFeedback12"
                  multiple
                  class="d-none"
                />
                <b-form-file
                  id="drivers-license-cover-page"
                  v-model.trim="driverLicenseScreens"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :disabled="form.driver.status == 3"
                  name="drivers-license-cover-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                  @input="uploadFiles('driverLicenseScreens')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback12">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.driverLicenseScreens.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in form.driverLicenseScreens"
                    :key="`file-${index}`"
                  >
                    <a
                      v-if="file"
                      :href="'/files/' + file.file ? file.file.guid : ''"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>&nbsp;<a
                      class="fa fa-close text-danger delete-multiple-file-button"
                      @click="deleteFileFromMultipleField('driverLicenseScreens', index)"
                    />;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1"
              md="4"
            >
              <b-form-group
                id="instruction-label"
                label="Инструкция (подписанная водителем собственноручно)"
                label-for="instruction"
              >
                <b-form-file
                  id="instruction"
                  v-model.trim="$v.form.instruction.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('instruction')"
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('instruction')"
                />
                <div
                  v-if="form.instruction && form.instruction.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.instruction.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.instruction.file)"
                  >{{ form.instruction.file.originalName }}</a>
                </div>
                <example-document code="instruction" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="infoSheet-label"
                label="Инф. Лист (подписанный водителем собственноручно)"
                label-for="infoSheet"
              >
                <b-form-file
                  id="infoSheet"
                  v-model.trim="$v.form.infoSheet.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('infoSheet')"
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('infoSheet')"
                />
                <div
                  v-if="form.infoSheet && form.infoSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.infoSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.infoSheet.file)"
                  >{{ form.infoSheet.file.originalName }}</a>
                </div>
                <example-document code="infoSheet" />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                v-if="accessType==='cargoFlow' || form.checkType===1"
                id="criminalZonesSheet-label"
                label="Лист криминогенных зон (подписанный водителем собственноручно)"
                label-for="criminalZonesSheet"
              >
                <b-form-file
                  id="criminalZonesSheet"
                  v-model.trim="$v.form.criminalZonesSheet.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('criminalZonesSheet')"
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('criminalZonesSheet')"
                />
                <div
                  v-if="form.criminalZonesSheet && form.criminalZonesSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.criminalZonesSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.criminalZonesSheet.file)"
                  >{{ form.criminalZonesSheet.file.originalName }}</a>
                </div>
                <example-document code="criminalZonesSheet" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="selfie-label"
                label="Фото водителя (селфи)"
                label-for="selfie"
              >
                <b-form-file
                  id="selfie"
                  v-model.trim="$v.form.selfie.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('selfie')"
                  name="selfie"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('selfie')"
                />
                <div
                  v-if="form.selfie && form.selfie.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.selfie.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.selfie.file)"
                  >{{ form.selfie.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='container'"
              md="4"
            >
              <b-form-group
                id="power-of-attorney-label"
                label="Довереность от перевозчика"
                label-for="power-of-attorney"
              >
                <b-form-file
                  id="powerOfAttorney"
                  v-model.trim="$v.form.powerOfAttorney.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('powerOfAttorney')"
                  name="power-of-attorney"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('powerOfAttorney')"
                />
                <div
                  v-if="form.powerOfAttorney && form.powerOfAttorney.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.powerOfAttorney.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.powerOfAttorney.file)"
                  >{{ form.powerOfAttorney.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1"
              md="12"
              class="bold"
            >
              Важно! Все пункты в информационном листе, инструкции, листе криминогенных зон обязательны для
              заполнения и подписания водителем, кроме данных о перевозчике и направлении рейса.
              Не заполненные и не подписанные водителем документы не будут приниматься к проверке
            </b-col>
            <b-col
              v-else-if="accessType==='container'"
              md="12"
              class="bold"
            >
              Важно! Все пункты в информационном листе обязательны для заполнения и подписания водителем,
              кроме данных о перевозчике и направлении рейса.
              Не заполненный и не подписанный водителем информационный лист я не будут приниматься к проверке.
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Контактная информация
          </h5>
          <multiple-phones-input
            v-model.trim="form.driverPhones"
            :driver-status="form.driver.status"
          />
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по допускам
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-checkbox
                id="access-auto"
                v-model="$v.form.driver.driverAccessAuto.$model"
                name="access-auto"
                disabled
                @change="accessAutoDependency"
              >
                Допуск Авто
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-container"
                v-model="$v.form.driver.driverAccessContainer.$model"
                name="access-container"
                disabled
              >
                Допуск Контейнер
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-cargo-flow"
                v-model="$v.form.driver.driverAccessCargoFlow.$model"
                name="access-cargo-flow"
                disabled
              >
                Допуск Грузопровод
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по доверенностям
          </h5>
          <p v-if="form.attorneys.length">
            Доверенности
          </p>
          <ul
            v-if="form.attorneys"
            class="route-attorneys"
          >
            <li
              v-for="(attorney, index) in form.attorneys"
              :key="index"
              class="route-attorney"
            >
              Доверенность <a
                :href="`/contractors/id${$store.state.user.ownerId}/attorney/id${attorney.id}`"
                target="_blank"
              >№{{ attorney.number }}</a>
              <span
                v-if="attorney.status === 0"
                class="text-secondary"
              > (На рассмотрении)</span>
              <span
                v-else-if="attorney.status === 1"
                class="text-success"
              > (Действует)</span>
              <span
                v-else-if="attorney.status === 2"
                class="text-danger"
              > (Аннулирована)</span><br>
              Действует с {{ formatDatetime(attorney.dateStart) }} по {{ formatDatetime(attorney.dateEnd) }}<br>
              <span v-if="!attorney.file">
                Загруженный файл: <a
                  v-if="attorney.file"
                  :href="'/files/' + attorney.file ? attorney.file.file.guid : ''"
                  target="_blank"
                  @click.prevent="downloadFile(attorney.file.file)"
                >{{ attorney.file.file.originalName }}</a>
              </span>
            </li>
          </ul>
          <b-button
            variant="primary"
            size="sm"
            class="mb-3"
            :class="{'loading' : loading}"
            :disabled="loading"
            @click="confirmAttorneysAddModal.show = true"
          >
            Добавить доверенность
          </b-button>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="disableSave"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>

    <b-modal
      v-model="confirmAttorneysAddModal.show"
      title="Добавление доверенности"
      class="modal-warning"
      cancel-title="Назад"
      :ok-disabled="!Boolean(confirmAttorneysAddModal.number) || !Boolean(confirmAttorneysAddModal.date) || !Boolean(confirmAttorneysAddModal.dateStart) || !Boolean(confirmAttorneysAddModal.dateEnd) || !Boolean(confirmAttorneysAddModal.file)"
      ok-title="Отправить"
      @ok="attorneysAdd()"
    >
      <p>Заполните поля и нажмите кнопку "Отправить", доверенность автоматически прикрепится к данному водителю в статусе "На рассмотрении"</p>
      <b-form-group
        id="attorneyDriverInputGroup"
        label="Водитель"
        label-for="attorney-driver"
      >
        <b-form-input
          id="attorney-driver"
          v-model.trim="confirmAttorneysAddModal.driver"
          type="text"
          name="attorney-driver"
          autocomplete="attorney-driver"
          disabled
        />
      </b-form-group>
      <b-form-group
        id="attorneyNumberInputGroup"
        label="Номер доверенности"
        label-for="attorney-number"
      >
        <b-form-input
          id="attorney-number"
          v-model.trim="confirmAttorneysAddModal.number"
          type="text"
          name="attorney-number"
          autocomplete="attorney-number"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyDateInputGroup"
        label="Дата доверенности"
        label-for="attorney-date"
      >
        <el-date-picker-input
          id="attorney-date"
          v-model.trim="confirmAttorneysAddModal.date"
          type="date"
          name="attorney-date"
          autocomplete="attorney-date"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyDateStartInputGroup"
        label="Период действия с"
        label-for="attorney-date-start"
      >
        <el-date-picker-input
          id="attorney-date-start"
          v-model.trim="confirmAttorneysAddModal.dateStart"
          type="date"
          name="attorney-date-start"
          autocomplete="attorney-date-start"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyDateEndInputGroup"
        label="Период действия по"
        label-for="attorney-date-end"
      >
        <el-date-picker-input
          id="attorney-date-end"
          v-model.trim="confirmAttorneysAddModal.dateEnd"
          type="date"
          name="attorney-date-end"
          autocomplete="attorney-date-end"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyInputGroup"
        label="Доверенность"
        label-for="attorney"
      >
        <b-form-file
          id="attorney"
          v-model="confirmAttorneysAddModal.file"
          v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
          name="attorney"
          accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
          placeholder="Файл не выбран"
          browse-text="Выбрать"
          drop-placeholder="Перетащите файл сюда"
          @input="uploadAttorneyFile('file')"
        />
        <div
          v-if="confirmAttorneysAddModal.file.file"
          class="mt-2"
        >
          Загруженный файл:
          <a
            v-if="confirmAttorneysAddModal.file"
            :href="'/files/' + confirmAttorneysAddModal.file ? confirmAttorneysAddModal.file.file.guid : ''"
            target="_blank"
            @click.prevent="downloadFile(confirmAttorneysAddModal.file.file)"
          >{{ confirmAttorneysAddModal.file.file.originalName }}</a>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import {validationMixin} from 'vuelidate';
import {maxLength, minLength, required} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {
  contractorsDriverRead, contractorsDriverUpdate,
  contractorsAttorneyCreate, documentCreate,
  passportTypesList, downloadFile, driverCheckLocations, contractorRead,
} from '../../services/api';
import moment from 'moment';
import multiplePhonesInput from '../Forms/MultiplePhonesInput';
import ExampleDocument from '@/views/ExampleDocuments/ExampleDocument';

export default {
  name: 'DriversEdit',
  components: {multiplePhonesInput, ElDatePickerInput, ExampleDocument},
  mixins: [validationMixin, notifications],
  props: ['driverId'],
  data() {
    return {
      driverCheckLocations: [],
      accessType: null,
      passportTypes: [],
      contractFiles: [],
      driverLicenseScreens: [],
      form: {
        driver: {
          id: null,
          status: null,
          passportType: null,
          passportSeries: '',
          passportNumber: '',
          passportIssueDate: '',
          passportIssuedBy: '',
          surname: '',
          name: '',
          patronymic: '',
          birthDate: '',
          driverLicense: '',
          driverAccessAuto: false,
          driverAccessContainer: false,
          driverAccessCargoFlow: false,
          inn: null,
        },
        checkLocation: null,
        instruction: null,
        infoSheet: null,
        criminalZonesSheet: null,
        powerOfAttorney: null,
        selfie: null,
        checkType: null,
        passportScreenFirst: null,
        passportScreenSecond: null,
        contractFiles: [],
        driverLicenseScreens: [],
          driverPhones: [{
          countryCode: '',
          areaCode: '',
          localNumber: '',
          additionalNumber: '',
        }],

        attorneys: [],
      },
      confirmAttorneysAddModal: {
        show: false,
        driver: '',
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
      expressDriverCheck: true,
    };
  },
  computed: {
    disableSave() {
      console.log('warn', this.$v.form.$invalid);
      return (
        this.$v.form.driver.$invalid ||
          this.$v.form.$invalid ||
          this.loading ||
          !this.validPhones ||
          (
            !this.form.driver.driverAccessAuto &&
          !this.form.driver.driverAccessContainer &&
          !this.form.driver.driverAccessCargoFlow
          ) ||
          !this.form.contractFiles.length > 0 ||
          !this.form.driverLicenseScreens.length > 0
      );
    },
    formStr() {
      const calculationFormData = Object.assign({}, this.form);
      calculationFormData.driver.passportIssueDate = moment(calculationFormData.driver.passportIssueDate).format('YYYY-MM-DD');
      calculationFormData.driver.birthDate = moment(calculationFormData.driver.birthDate).format('YYYY-MM-DD');
      calculationFormData.attorneys.map((attorney) => {
        attorney.date = moment(attorney.date).format();
        attorney.dateEnd = moment(attorney.dateEnd).format();
        attorney.dateStart = moment(attorney.dateStart).format();
        return attorney;
      });
      calculationFormData.checkLocation = this.form.checkLocation? {id: this.form.checkLocation} : null;
      calculationFormData.driverPhones = calculationFormData.driverPhones.filter((phone)=>!phone.deletionMark);
      return calculationFormData;
    },
    attorneyFormStr() {
      const calculationFormData = {};
      calculationFormData.drivers = [{id: this.form.driver.id}];
      if (this.confirmAttorneysAddModal.number) {
        calculationFormData.number = this.confirmAttorneysAddModal.number;
      }
      if (this.confirmAttorneysAddModal.date) {
        calculationFormData.date = moment(this.confirmAttorneysAddModal.date).format('YYYY-MM-DD');
      }
      if (this.confirmAttorneysAddModal.dateStart) {
        calculationFormData.dateStart = moment(this.confirmAttorneysAddModal.dateStart).format('YYYY-MM-DD');
      }
      if (this.confirmAttorneysAddModal.dateEnd) {
        calculationFormData.dateEnd = moment(this.confirmAttorneysAddModal.dateEnd).format('YYYY-MM-DD');
      }
      if (this.confirmAttorneysAddModal.file) {
        calculationFormData.file = this.confirmAttorneysAddModal.file;
      }
      return calculationFormData;
    },
    isValid() {
      return (!this.$v.form.driver.$anyError && !this.$v.form.$anyError);
    },
    validPhones() {
      let valid = true;
      this.form.driverPhones.forEach((driverPhone) => {
        if (!driverPhone.countryCode || !driverPhone.areaCode || !driverPhone.localNumber) {
          valid = false;
        }
      });
      return valid;
    },
  },
  validations() {
    const form = {
      driver: {
        passportType: {
          required,
        },
        passportSeries: {
          required,
        },
        passportNumber: {
          required,
        },
        passportIssueDate: {
          required,
        },
        passportIssuedBy: {
          required,
        },
        surname: {
          required,
        },
        name: {
          required,
        },
        patronymic: '',
        birthDate: {
          required,
        },
        driverLicense: {
          required,
        },
        driverAccessAuto: '',
        driverAccessContainer: '',
        driverAccessCargoFlow: '',
        inn: {
          required,
          minLength: minLength(12),
          maxLength: maxLength(12),
        },
      },
      checkType: {
        required,
      },
      passportScreenFirst: {
        required,
      },
      passportScreenSecond: {
        required,
      },
      contractFiles: {
        required,
      },
      driverLicenseScreens: {
        required,
      },
      selfie: '',
      instruction: '',
      infoSheet: '',
      criminalZonesSheet: '',
      powerOfAttorney: '',

    };
    return {form};
  },
  mounted() {
    this.passportTypesList();
    this.getLocations();
    this.checkExpressCheck();
    this.contractorsDriverRead(this.$store.state.user.ownerId, this.driverId);
  },
  methods: {
    async getLocations() {
      const response = await driverCheckLocations({});
      if (response && response.status === 200) {
        this.driverCheckLocations = response.data;
      }
    },
    async checkExpressCheck() {
      const responseCont = await contractorRead(this.$store.state.user.ownerId);
      if (responseCont && responseCont.status === 200) {
        this.expressDriverCheck = responseCont.data.expressDriverCheck;
      }
    },
    downloadFile,
    phoneValidate(e) {
      if (/_/.test(e.$model)) {
        e.$model = '';
      }
    },
    chkSeriaField(e) {
      this.form.driver.passportSeries = e.currentTarget.value.replace(/\s+/g, '').toUpperCase();
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    accessAutoDependency(value) {
      if (value) {
        this.form.driver.driverAccessContainer = true;
        this.form.driver.driverAccessCargoFlow = true;
      }
    },
    async attorneysAdd() {
      this.loading = true;
      const response = await contractorsAttorneyCreate(this.$store.state.user.ownerId, this.attorneyFormStr);
      if (response && response.status === 201) {
        this.showSuccess('Доверенность создана');
        this.contractorsDriverRead(this.$store.state.user.ownerId, this.driverId);
        this.confirmAttorneysAddModal.number = '';
        this.confirmAttorneysAddModal.date = '';
        this.confirmAttorneysAddModal.dateStart = '';
        this.confirmAttorneysAddModal.dateEnd = '';
        this.confirmAttorneysAddModal.file = '';
      }
      this.loading = false;
    },
    async passportTypesList(params) {
      this.loading = true;
      const response = await passportTypesList(params);
      if (response && response.status === 200) {
        this.passportTypes = response.data.items.map((type) => {
          return {
            value: type.id,
            text: type.type,
          };
        });
      }
      this.loading = false;
    },
    async contractorsDriverRead(contractorId, driverId) {
      this.loading = true;
      const response = await contractorsDriverRead(contractorId, driverId);
      if (response && response.status === 200) {
        let fio = response.data.driver.surname + ' ' + response.data.driver.name;
        if (response.data.driver.patronymic) {
          fio = fio + ' ' + response.data.driver.patronymic;
        }
        this.confirmAttorneysAddModal.driver = fio;
        Object.keys(response.data.driver).map((item) => {
          if (item === 'passportType') {
            this.form.driver[item] = response.data.driver[item].id;
          } else if (
            item === 'guid' ||
            item === 'driverBlockedSecurity' ||
            item === 'createdOn' ||
            item === 'updatedOn'
          ) {
            // nothing
          } else {
            this.form.driver[item] = response.data.driver[item];
          }
        });
        if (response.data.contractFiles) {
          this.form.contractFiles = response.data.contractFiles;
        }
        if (response.data.driverLicenseScreens) {
          this.form.driverLicenseScreens = response.data.driverLicenseScreens;
        }
        if (response.data.driver.birthDate) {
          this.form.driver.birthDate = moment(response.data.driver.birthDate).format('YYYY-MM-DD');
        }
        if (response.data.passportScreenFirst) {
          this.form.passportScreenFirst = response.data.passportScreenFirst;
        }
        if (response.data.passportScreenSecond) {
          this.form.passportScreenSecond = response.data.passportScreenSecond;
        }
        if (response.data.instruction) {
          this.form.instruction = response.data.instruction;
        }
        if (response.data.infoSheet) {
          this.form.infoSheet = response.data.infoSheet;
        }
        if (response.data.criminalZonesSheet) {
          this.form.criminalZonesSheet = response.data.criminalZonesSheet;
        }
        if (response.data.powerOfAttorney) {
          this.form.powerOfAttorney = response.data.powerOfAttorney;
        }
        if (response.data.selfie) {
          this.form.selfie = response.data.selfie;
        }
        if (response.data.checkLocation) {
          this.form.checkLocation = response.data.checkLocation.id;
        }       
        if (response.data.checkType !== undefined) {
          this.form.checkType = response.data.checkType;
        } else {
          this.form.checkType = 1;
        }     
        if (response.data.driver.driverPhones) {
          this.form.driverPhones = response.data.driver.driverPhones;
        }       
        if (this.form.driver.driverAccessAuto) {
          this.accessType = 'auto';
        } else if (this.form.driver.driverAccessContainer) {
          this.accessType = 'container';
        } else if (this.form.driver.driverAccessCargoFlow) {
          this.accessType = 'cargoFlow';
        }
        if (response.data.attorneys) {
          response.data.attorneys.map((attorney) => {
            if (attorney.status === 2) {
              const updateTime = moment(attorney.updatedOn).format('x');
              const currentTime = moment().format('x');
              const limit = 604800000;
              if (currentTime - updateTime >= limit) {
                attorney.hidden = true;
              }
            }
            if (!attorney.file) {
              attorney.file = {};
            }
            this.form.attorneys.push(attorney);
          });
        }
      }
      this.loading = false;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorsDriverUpdate(this.$store.state.user.ownerId, this.driverId, this.formStr);
        if (response && response.status === 200) {
          if (this.form.driver.status === 1 || this.form.driver.status === 3) {
            this.showSuccess('Данные по водителю успешно сохранены и будут применены после проверки логистом');
          } else {
            this.showSuccess('Водитель обновлен');
          }
          this.$router.push({path: '/drivers'});
        }
        this.loading = false;
      }
    },
    chkState(val) {
      if (val.indexOf('.') !== -1) {
        const parameters = val.split('.');
        const field = this.$v.form[parameters[0]][parameters[1]];
        if (!field.$dirty) {
          return null;
        } else return !field.$invalid;
      } else {
        const field = this.$v.form[val];
        if (!field.$dirty) {
          return null;
        } else return !field.$invalid;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });
      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    async licenseScreenCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    async attorneyScreenCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.confirmAttorneysAddModal[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    uploadFiles(key) {
      const files = this[key];
      this[key] = [];
      files.forEach((file) => {
        if (file) {
          if (file.size >= 15 * 1024 * 1024) {
            this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
          } else {
            this.getBase64(file).then((data) => {
              this.loading = true;
              const upload = {
                base64file: data,
                originalName: file.name,
              };
              this.multipleFieldFilesCreate(key, upload);
            });
          }
        }
      });
    },
    async multipleFieldFilesCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.showSuccess('Документ загружен');
        this.form[key].push(response.data);
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadFile(key) {
      const file = this.form[key];
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          this.loading = true;
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.licenseScreenCreate(key, upload);
        });
      }
    },
    uploadAttorneyFile(key) {
      const file = this.confirmAttorneysAddModal[key];
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.attorneyScreenCreate(key, upload);
        });
      }
    },
    deleteFileFromMultipleField(field, index) {
      this.form[field].splice(index, 1);
    },
    clearCheckType() {
      this.form.checkType = null;
      this.form.checkLocation = null;
      if (!this.expressDriverCheck || this.accessType !== 'auto') {
        this.form.checkType = 0;
      }
      if (this.accessType === 'auto') {
        this.form.driver.driverAccessAuto = true;
        this.form.driver.driverAccessCargoFlow = true;
        this.form.driver.driverAccessContainer = true;
      } else {
        this.form.driver.driverAccessAuto = false;
        this.form.driver.driverAccessContainer = this.accessType === 'container';
        this.form.driver.driverAccessCargoFlow = this.accessType === 'cargoFlow';
      }
    },
  },
};
</script>

<style lang="scss">
</style>



